<template>
  <div>
    <v-card
      flat
      class="mt-4"
    >
      <v-card-title
        primary-title
        class="justify-center"
      >
        <img
          :src="$_get_image('lock.png')"
          width="80"
          class="d-block"
        >
      </v-card-title>
      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="handleSubmit"
      >
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="form.old_password"
                :append-icon="show1 ? 'mdil-eye' : 'mdil-eye-off'"
                :type="show1 ? 'text' : 'password'"
                :label="$t('Old password')"
                outlined
                dense
                :rules="oldPasswordRules"
                autocomplete="off"
                @click:append="show1 = !show1"
              />
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="form.new_password"
                :append-icon="show2 ? 'mdil-eye' : 'mdil-eye-off'"
                :type="show2 ? 'text' : 'password'"
                :label="$t('New password')"
                outlined
                dense
                :rules="newPasswordRules"
                autocomplete="off"
                @click:append="show2 = !show2"
              />
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="form.confirm_password"
                :append-icon="show3 ? 'mdil-eye' : 'mdil-eye-off'"
                :type="show3 ? 'text' : 'password'"
                :label="$t('Confirm Password')"
                outlined
                dense
                :rules="confirmPasswordRules"
                autocomplete="off"
                @click:append="show3 = !show3"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions class="my-3">
          <v-btn
            type="submit"
            style="width: 100%"
            depressed
            color="primary"
          >
            {{ $t('Save') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      valid: true,
      form: {},
      show1: false,
      show2: false,
      show3: false,
      oldPasswordRules: [v => !!v || this.$t('Old password is required')],
      newPasswordRules: [v => !!v || this.$t('Password is required')],
      confirmPasswordRules: [v => !!v || this.$t('Confirm password is required')]
    }
  },

  methods: {
    ...mapActions([
      'setSnack'
    ]),

    handleSubmit() {
      if (this.$refs.form.validate()) {
        if (this.form.new_password !== this.form.confirm_password) {
          this.$_notify('Confirm password not match with password', 'error')
          return
        }

        axios.put('change_password/', this.form).then(() => {
          this.$_notify('Password changed successfully')
          this.form = {}
          this.$refs.form.resetValidation()
        })

      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
